
// -------------------------------------------------------------------------------------
// Typography
// -------------------------------------------------------------------------------------

@import url('https://fonts.googleapis.com/css2?family=Archivo&display=swap');

// @font-face {
//   font-family: 'Sohne Breit';
//   src: url('../assets/fonts/SohneBreit-Halbfett.eot');
//   src: url('../assets/fonts/SohneBreit-Halbfett.eot?#iefix')
//       format('embedded-opentype'),
//     url('../assets/fonts/SohneBreit-Halbfett.woff2') format('woff2'),
//     url('../assets/fonts/SohneBreit-Halbfett.woff') format('woff'),
//     url('../assets/fonts/SohneBreit-Halbfett.ttf') format('truetype'),
//     url('../assets/fonts/SohneBreit-Halbfett.svg#SohneBreit-Halbfett')
//       format('svg');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'Sohne';
  src: url('../assets/fonts/SohneTest-Buch.woff2') format('woff2'),
       url('../assets/fonts/SohneTest-Buch.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sohne';
  src: url('../assets/fonts/SohneTest-Kraftig.woff2') format('woff2'),
       url('../assets/fonts/SohneTest-Kraftig.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos';
  src: url('../assets/fonts/TiemposTextTest-Regular.woff2') format('woff2'),
       url('../assets/fonts/TiemposTextTest-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'Calluna';
//   src: url('../assets/fonts/Calluna-Regular.woff2') format('woff2'),
//        url('../assets/fonts/Calluna-Regular.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Signifier';
//   src: url('../assets/fonts/Signifier-Regular.eot');
//   src: url('../assets/fonts/Signifier-Regular.eot?#iefix')
//       format('embedded-opentype'),
//     url('../assets/fonts/Signifier-Regular.woff2') format('woff2'),
//     url('../assets/fonts/Signifier-Regular.woff') format('woff'),
//     url('../assets/fonts/Signifier-Regular.ttf') format('truetype'),
//     url('../assets/fonts/Signifier-Regular.svg#Signifier-Regular') format('svg');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Signifier';
//   src: url('../assets/fonts/SignifierTest-Bold.otf') format('opentype');
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }

$font-sans: 'Sohne', sans-serif;
$font-strong: 'Sohne', sans-serif;
$font-serif: 'Tiempos', serif;
// $font-serif: 'Signifier', serif;
// $font-display: 'Sohne Breit', sans-serif;

// -------------------------------------------------------------------------------------

@mixin font-style($font-fam, $xs, $sm, $md, $lg, $xl, $letter-sp, $line-h, $weight, $style) {
  font-family: $font-fam;
  font-size: $xs;
  letter-spacing: $letter-sp;
  line-height: $line-h;
  font-weight: $weight;
  font-style: $style;
  font-stretch: normal;
  @include media-breakpoint-up(sm) { font-size: $sm; }
  @include media-breakpoint-up(md) { font-size: $md; }
  @include media-breakpoint-up(lg) { font-size: $lg; }
  @include media-breakpoint-up(xl) { font-size: $xl; }
}

// ------------------ font type         xs      sm      md      lg      xl      lsp     lh      w     style

@mixin font-sans-m {
  @include font-style($font-sans,       17px,   17px,   17px,   17px,   17px,   0.00em, 1.2em,  400,  normal );
}
@mixin font-sans-s {
  @include font-style($font-sans,       12px,   12px,   12px,   12px,   12px,   0.00em, 1.4em,  400,  normal );
}

@mixin font-serif-m {
  @include font-style($font-serif,      17px,   17px,   17px,   17px,   17px,   0.00em, 1.35em, 400,  normal );
}
@mixin font-serif-l {
  @include font-style($font-serif,      24px,   26px,   26px,   26px,   26px,   0.00em, 1.2em,  400,  normal );
}

@mixin font-strong-xl {
  @include font-style($font-strong,     9vw,    54px,   54px,   54px,   54px,   0.00em, 1.05em, 500,  normal );
}
@mixin font-strong-ll  {
  @include font-style($font-strong,     30px,   34px,   34px,   34px,   34px,   0.00em, 1.05em, 500,  normal );
}
@mixin font-strong-ml  {
  @include font-style($font-strong,     24px,   26px,   26px,   26px,   26px,   0.00em, 1.05em, 500,  normal );
}
@mixin font-strong-m  {
  @include font-style($font-strong,     17px,   17px,   17px,   17px,   17px,   0.00em, 1.15em, 500,  normal );
}
@mixin font-strong-s  {
  @include font-style($font-strong,     12px,   12px,   12px,   12px,   12px,   0.02em, 1.05em, 500,  normal );
}

.font-sans-m      { @include font-sans-m; }
.font-sans-s      { @include font-sans-s; }
.font-serif-m     { @include font-serif-m; }
.font-serif-l     { @include font-serif-l; }
.font-strong-xl   { @include font-strong-xl; }
.font-strong-ll   { @include font-strong-ll; }
.font-strong-ml    { @include font-strong-ml; }
.font-strong-m    { @include font-strong-m; }
.font-strong-s    { @include font-strong-s; }







