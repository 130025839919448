@charset "UTF-8";

// -------------------------------------------------------------------------------------
// Global sass variables
// -------------------------------------------------------------------------------------

$purple: #5100ff;
$purple-dark: #371295;
$c1: #e8aeae;
$c2: #c0a9f2;
$c3: #faaf76;
$c1-strong: #ff6fa7;
$c2-strong: #5100ff;
$c3-strong: #ff9c50;
$black: #131415;
$blackish: #1c232a;
$grey-lll: #e9ecef;
$grey-l: #979797;

$space: 20px;
$header-h: 60px;
$max-w: 1360px;

// -------------------------------------------------------------------------------------
// Imports (after variables)
// -------------------------------------------------------------------------------------

@import '.~bootstrap/scss/_functions.scss';
@import '.~bootstrap/scss/_variables.scss';
@import '.~bootstrap/scss/mixins/_breakpoints.scss';
@import './styles/_boogrid';
@import './styles/_typography';
@import './styles/_mixins';

// -------------------------------------------------------------------------------------
// Reset
// -------------------------------------------------------------------------------------

*:not(i) {
  &,
  &::before,
  &::after {
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
  }
}

:root {
  color: $black;
}

button,
input,
optgroup,
select,
textarea,
html input[type='button'],
input[type='reset'],
input[type='submit'],
button[disabled],
html input[disabled],
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type='checkbox'],
input[type='radio'],
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button,
input[type='search'],
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline-width: 0;
}

// -------------------------------------------------------------------------------------
// Global helper classes
// -------------------------------------------------------------------------------------

.color-c1 {
  color: $c1-strong;
}
.color-c2 {
  color: $c2-strong;
}
.color-c3 {
  color: $c3-strong;
}
.color-purple {
  color: $purple !important;
}
.color-white {
  color: white;
}
.color-white-50 {
  color: rgba(white, 0.5);
}
.color-white-40 {
  color: rgba(white, 0.4);
}
.color-white-30 {
  color: rgba(white, 0.3);
}
.color-black-50 {
  color: rgba(black, 0.5);
}
.color-blackish-80 {
  color: rgba($blackish, 0.8);
}
.hover-white:hover {
  color: white;
}
.hover-white-50:hover {
  color: rgba(white, 0.5);
}
.hover-purple:hover {
  color: $purple;
}
.hover-u:hover {
  text-decoration: underline;
}
.opacity-0 {
  opacity: 0;
}
.bg-purple {
  background-color: $purple;
}
.bg-white {
  background-color: white;
}
.bg-black {
  background-color: black;
}
.bg-c1 {
  background-color: $c1;
}
.bg-c2 {
  background-color: $c2;
}
.bg-c3 {
  background-color: $c3;
}
.bg-grey-lll {
  background-color: $grey-lll;
}
.u {
  text-decoration: underline;
}
.upper {
  text-transform: uppercase;
}
.font-700 {
  font-weight: 700 !important;
}
.font-400 {
  font-weight: 400 !important;
}

.mt-space {
  margin-top: $space;
}
.mb-space {
  margin-bottom: $space;
}
.my-space {
  margin-top: $space;
  margin-bottom: $space;
}
.ml-space {
  margin-left: $space;
}
.mr-space {
  margin-right: $space;
}
.mx-space {
  margin-left: $space;
  margin-right: $space;
}
.mt-space-2 {
  margin-top: $space * 2;
}
.mb-space-2 {
  margin-bottom: $space * 2;
}
.my-space-2 {
  margin-top: $space * 2;
  margin-bottom: $space * 2;
}
.ml-space-2 {
  margin-left: $space * 2;
}
.mr-space-2 {
  margin-right: $space * 2;
}
.mx-space-2 {
  margin-left: $space * 2;
  margin-right: $space * 2;
}
.mt-space-3 {
  margin-top: $space * 3;
}
.mb-space-3 {
  margin-bottom: $space * 3;
}
.my-space-3 {
  margin-top: $space * 3;
  margin-bottom: $space * 3;
}
.ml-space-3 {
  margin-left: $space * 3;
}
.mr-space-3 {
  margin-right: $space * 3;
}
.mx-space-3 {
  margin-left: $space * 3;
  margin-right: $space * 3;
}

.h-50vh {
  height: 50vh !important;
}
.h-60vh {
  height: 60vh !important;
}
.h-70vh {
  height: 70vh !important;
}
.h-80vh {
  height: 80vh !important;
}
.h-90vh {
  height: 90vh !important;
}
.h-100vh {
  height: 100vh !important;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-visible {
  overflow: visible;
}

.break-inside-avoid {
  break-inside: avoid;
  * {
    break-inside: avoid;
  }
}

.label {
  @include font-sans-s;
  color: rgba(black, 0.5);

  &.l {
    @include font-strong-m;
  }

  &a,
  a {
    @include transition(color 400ms);
    &:hover,
    a:hover {
      color: $purple !important;
    }
  }
}

a.color-c1:hover {
  color: $c1-strong !important;
}
a.color-c2:hover {
  color: $c2-strong !important;
}
a.color-c3:hover {
  color: $c3-strong !important;
}

// -------------------------------------------------------------------------------------
// General tags
// -------------------------------------------------------------------------------------

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.menu-open,
  &.search-open {
    overflow-y: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

main {
  min-height: 800px;
  min-height: calc(100vh - 140px);
  padding-top: $header-h;
  background-color: $grey-lll;
}

em {
  color: $purple;
  font-style: normal;
}

p {
  max-width: 28em;
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  &:hover {
    color: $purple;
  }
  &.button {
    height: 30px;
    border-radius: calc(30px / 2);
    @include font-serif-m;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $blackish;
    color: white;
    text-decoration: none;
    padding: 0 0.9em;
    padding-top: 1px;
    padding-bottom: 0;
    @include transition(background-color 150ms, color 150ms);
    &:hover {
      background-color: darken($purple, 15%);
      background-color: $purple;
    }

    // &.l {
    //   height: 46px;
    //   border-radius: calc(46px / 2);
    //   @include font-strong-ml;
    //   padding: 0 .65em;
    //   text-transform: uppercase;
    //   padding-top: 0;
    //   padding-bottom: 1px;
    // }

    &.l {
      @include font-strong-ml;
      height: auto;
      border-radius: 200px;
      display: inline-block;
      padding-top: inherit;
      padding-bottom: inherit;
      padding: 7px 0.5em 9px;
      text-transform: uppercase;

      &.white {
        color: white;
        &:hover {
          color: $purple;
          background-color: white;
        }
      }
    }

    &.selector,
    &.selector:hover {
      @include font-strong-s;
      text-transform: uppercase;
      background-color: rgba($purple, 0);
      color: black;
      &.white {
        color: white;
      }
      margin-left: -3px;
      &.active {
        background-color: $purple;
        color: white;
        &.white-active {
          color: $purple;
          background-color: white;
        }
      }
    }
  }

  &.external:hover::after {
    content: ' ↗';
  }
}

h2 {
  @include font-strong-ll;
  margin-bottom: $space * 1.5;
}

hr {
  height: 0;
  border-top: 1px solid $grey-l;

  &.large-top {
    margin-top: 120px;
  }
  &.large-bottom {
    margin-bottom: 120px;
  }
}

// -------------------------------------------------------------------------------------
// Grid
// -------------------------------------------------------------------------------------

.pad {
  padding-top: $space * 4;
}
.pad-b {
  padding-bottom: $space * 6;
}
.pad-small {
  padding-top: $space * 2;
}
.pad-small-b {
  padding-bottom: $space * 4;
}

// -------------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------------

.two-cols {
  column-count: 2;
  column-gap: 30px;
}
.two-cols-sm {
  @include media-breakpoint-up(sm) {
    column-count: 2;
    column-gap: 30px;
  }
}
.two-cols-md {
  @include media-breakpoint-up(md) {
    column-count: 2;
    column-gap: 30px;
  }
}
.two-cols-lg {
  @include media-breakpoint-up(lg) {
    column-count: 2;
    column-gap: 30px;
  }
}
.two-cols-xl {
  @include media-breakpoint-up(xl) {
    column-count: 2;
    column-gap: 30px;
  }
}

// --------------------
// Header
// --------------------

#header {
  position: fixed;
  z-index: 200;
  width: 100%;
  left: 0;
  top: 0;
  background-color: white;
  @include box-shadow(0, 0, 10px, rgba(black, 0.1));

  .font-serif-m.header-serif {
    // font-size: 16px;
  }

  .wrapper {
    height: $header-h;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      &.search {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    .right {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    .right-mobile {
      display: block;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    a.item {
      color: $purple;
      display: inline-block;
      text-decoration: none;
      padding: 2px 0;
      @include font-strong-m;

      &:hover,
      &.active {
        border-bottom: 2px solid $purple;
      }

      & + a.item {
        margin-left: $space;
      }
    }
  }

  .header-language-el {
    color: $grey-l;
    @include font-strong-s;
    &:hover,
    &.active {
      color: $purple;
    }
  }

  .general-search {
    background-color: $grey-lll;
    height: 37px;
    border-radius: 18.5px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    min-width: 350px;
    max-width: 350vw;
    width: auto;

    @include media-breakpoint-down(md) {
      min-width: 0;
      max-width: none;
      width: 250px;
    }
    @include media-breakpoint-only(xs) {
      width: 100%;
    }

    .search-field-wrapper {
      flex-grow: 1;
      position: relative;
      padding-top: 2px;

      .react-autosuggest__container {
      }
      .react-autosuggest__container--open {
      }
      input.react-autosuggest__input {
        width: 100%;
        padding: 0 8px;
        background-color: transparent;
        @include font-strong-m;
        outline: none;
      }
      .react-autosuggest__input--open {
      }
      .react-autosuggest__input--focused {
      }
      .react-autosuggest__suggestions-container {
        opacity: 0;
        position: absolute;
        top: 52px;
        left: -32px;
        background-color: white;
        z-index: 200;
        width: calc(100% + 60px);
        max-height: 70vh;
        overflow-y: scroll;
        border-radius: 10px;
        @include box-shadow(0, 0, 20px, rgba(black, 0));
        @include media-breakpoint-up(sm) {
          min-width: 450px;
          left: -232px;
        }
        @include media-breakpoint-up(md) {
          left: -132px;
        }
      }
      .react-autosuggest__suggestions-container--open {
        opacity: 1;
      }
      .react-autosuggest__suggestions-list {
        padding: 4px 0;
      }
      .react-autosuggest__suggestion {
        margin: 0 15px;
        padding: 8px 0;
        border-top: 1px solid $grey-lll;
        @include font-sans-m;
        color: $black;
        list-style: none;
      }
      .react-autosuggest__suggestion--first {
        border-top: 0;
      }
      .react-autosuggest__suggestion--highlighted {
        // background-color: $grey-lll;
        cursor: pointer;
        color: $purple;
        .suggestion .line-1 .suggestion-with-highlights p em {
          color: $purple !important;
        }
      }
      .react-autosuggest__section-container {
      }
      .react-autosuggest__section-container--first {
      }
      .react-autosuggest__section-title {
      }
    }

    // input {
    //   flex-grow: 1;
    //   padding: 0 8px;
    //   background-color: transparent;
    //   @include font-strong-m;
    // }

    a.close {
      @include font-strong-ml;
      color: $purple;
      position: relative;
      top: -3px;
    }
  }
}

.sub-header {
  height: $space * 2;
  position: sticky;
  top: $header-h;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $purple;
  color: white;
  z-index: 100;
  .row {
    position: relative;
    &::before,
    &::after {
      background-color: rgba(red, 0.2);
      position: absolute;
      top: 0;
      bottom: 0;
      width: 15px;
      z-index: 2;
      display: inline-block;
      content: '';
    }
    &::before {
      left: 0;
      @include linearGradientH(rgba($purple, 1), rgba($purple, 0));
    }
    &::after {
      right: 0;
      @include linearGradientH(rgba($purple, 0), rgba($purple, 1));
    }

    .col-12 {
      padding: 10px 0;
      max-width: 100%;
      overflow-x: scroll;
      display: flex;
      flex-flow: row nowrap;
    }
  }
  a {
    color: white;

    // @include font-strong-m;
    @include font-strong-s;
    text-transform: uppercase;

    display: inline-block;
    margin-right: 1em;
    white-space: nowrap;

    &:first-child {
      padding-left: 15px;
    }
    &:last-child {
      padding-right: 30px;
    }
  }
}

// --------------------
// Search header
// --------------------

.title-and-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  // margin-bottom: $space;
  border-bottom: 1px solid rgba(0, 0, 0, 0);

  &.open {
    border-bottom: 1px solid black;
    padding-bottom: 0;
    margin-top: -6px;
    margin-bottom: 4px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: calc(100% - 35px);

    input {
      display: inline-block;
      background-color: transparent;
      max-width: calc(100% - 30px);
      flex-grow: 1;
      height: 1.4em;
      outline-width: 0;

      &::placeholder {
        color: rgba(black, 0.3);
      }
    }
  }

  .open-search-link {
    @include font-strong-ll;
    display: inline-flex;
    align-items: center;
  }
}

#list-title-anchor {
  top: -60px;
  position: relative;
}

// --------------------
// Menu mobile
// --------------------

#menu-cover {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $purple-dark;
  pointer-events: none;
  opacity: 0;
  @include transition(opacity 400ms);
  @at-root body.menu-open & {
    opacity: 0.9;
    pointer-events: all;
  }
  @at-root body.search-open & {
    opacity: 0.9;
    pointer-events: all;
  }
}

#menu-mobile {
  position: fixed;
  top: 0;
  height: 100vh;
  left: 100vw;

  width: 50vw;
  @include media-breakpoint-down(md) {
    width: 66vw;
  }
  @include media-breakpoint-down(sm) {
    width: 100vw;
  }

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: space-between;
  background-color: $purple;
  padding: 30px;
  padding-top: 0;
  z-index: 300;
  @include transition(transform 400ms);

  @at-root body.menu-open & {
    transform: translateX(-50vw);
    @include media-breakpoint-down(md) {
      transform: translateX(-66vw);
    }
    @include media-breakpoint-down(sm) {
      transform: translateX(-100vw);
    }
  }

  @include media-breakpoint-up(lg) {
    transform: translateX(0) !important;
  }

  .top {
    height: $header-h;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .middle {
    .item-large {
      @include font-serif-m;
      @include font-strong-ll;
      display: block;
      padding: $space * 0.5 0;
      color: white;
    }
  }
  .bottom {
    .xs-language-el {
      color: white;
      opacity: 0.5;
      @include font-strong-s;
      &:hover,
      &.active {
        opacity: 1;
      }
    }
  }
}

// --------------------
// Home
// --------------------

section.intro-sentence {
  // background-color: $grey-lll;
  padding-top: 12vh;
  padding-bottom: 12vh;
  @include media-breakpoint-down(md) {
    padding-top: $space * 3;
    padding-bottom: $space * 3;
  }

  .random-sentence {
    a {
      color: $purple;
    }
    .markdown-output {
      display: inline;
      p {
        display: inline;
      }
    }
  }
}

// .selectionButtons {
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
// }

// --------------------
// Geographic context
// --------------------

section.geographic-context {
  background-color: $purple;
  padding-top: $space * 1;
  padding-bottom: $space * 1;
  @include media-breakpoint-only(lg) {
    background-image: url('./assets/images/map.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 800px;
  }
}

// --------------------
// Statistic
// --------------------

.statistics {
  display: flex;
  justify-content: flex-start;
  // justify-content: space-between;
  @include media-breakpoint-down(lg) {
    padding-left: 5%;
    padding-right: 5%;
    justify-content: center;
  }
  @include media-breakpoint-down(md) {
    padding-left: 0px;
    padding-right: 0px;
  }
  padding: $space * 2 0;

  .statistic {
    min-width: 144px;
    width: 144px;
    min-height: 144px;
    height: 144px;
    background-color: $blackish;
    border-radius: 100%;
    margin: $space $space $space 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;

    .value {
      @include font-strong-ll;
    }

    .name {
      @include font-sans-s;
      margin-top: $space / 3;
    }

    &.l {
      min-width: 195px;
      width: 195px;
      min-height: 195px;
      height: 195px;
      margin: $space $space / 2;

      @include media-breakpoint-down(md) {
        min-width: 33vw;
        width: 33vw;
        min-height: 33vw;
        height: 33vw;
      }

      .value {
        @include font-strong-xl;
      }
    }
  }
}

// --------------------
// Empty card
// --------------------

.empty-card {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  max-width: 410px;
  padding-top: 20%;
  min-height: 180px;
}

// --------------------
// Indicator
// --------------------

section.indicators-intro {
  background-color: $purple;
  color: white;
}

.indicator-cards-container {
  margin-bottom: -15px;
}

section.cards-wrapper {
  // background-color: $grey-lll;

  .compensate-card-margin {
    //margin-top: $space * -3;
  }

  .card-indicator {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: calc(#{$space} - 5px) $space;
    background-color: white;
    height: 260px;
    cursor: pointer;
    @include transition(box-shadow 200ms);
    @include box-shadow(0, 0, 20px, rgba(black, 0));

    &:hover {
      @include box-shadow(0, 0, 20px, rgba(black, 0.15));
    }

    header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .dot {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 7px;
        margin-right: 5px;
      }
    }
    .content {
      // margin-bottom: $space;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: space-between;
      height: 100%;

      .top {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        .left {
          margin-bottom: $space * 1.5;
        }

        .right {
          align-self: flex-start;
          justify-self: flex-end;

          &.trend {
            // position: relative;
            // right: -10px;
            margin-right: -10px;
          }

          .related-viz-wrapper {
            display: flex;
            flex-direction: column;
            height: 100px;
            align-items: flex-start;
            justify-content: flex-start;
            width: 60px;
          }
        }
      }
      .bottom {
        .labels {
          margin-top: 4px;
        }
      }

      .stat-text {
        @include font-strong-xl;
        color: $purple;
      }
      .stat-unit {
        @include font-serif-m;
        margin-bottom: 4px;
        font-weight: 400;
        color: $purple;
      }
      .history {
        // width: 100px;
        // height: 50px;
        // background-color: $grey-lll;
        img {
          height: 40px;
        }
      }
    }
  }
}

.btn-download {
  padding: 4px;
  cursor: pointer;
  margin-top: 30px;
}

.visx-group.visx-axis.visx-axis-bottom .vx-axis-tick {
  transform: translate(-12px, 5px);
  &:first-of-type {
    transform: translate(11px, 5px) !important;
  }
}

.visx-line {
  stroke-dasharray: 2 4;
  opacity: 0.75;
}

.indicator-full {
  background-color: white;
  padding-top: $space * 4;
  padding-bottom: $space * 4;

  .stat-text {
    @include font-strong-xl;
    // font-size: 10vw !important;
    color: $purple;
  }
  .stat-unit {
    @include font-serif-m;
    color: $purple;
  }
}

// --------------------
// Mapping the food system
// --------------------

g.has-links {
  path.node {
    stroke: yellow;
    stroke-width: 5px;
  }
}

section.mapping {
  background-color: $blackish;
  position: relative;
  height: 100vw;

  @include media-breakpoint-up(sm) {
    height: 95vh;
  }

  .ui {
    position: relative;
    z-index: 2;
    @include linearGradientV(rgba($blackish, 0.6), rgba($blackish, 0));
    pointer-events: none;
    a {
      pointer-events: all;
    }
  }

  // --- Map filter
  text {
    &.selectable-filter {
      @include font-sans-m;
      fill: white;
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 0.8;
      }
      &.selected {
        opacity: 1;
      }
    }
    &.explanation {
      fill: white;
      opacity: 0.5;
    }
  }

  .mapping-viz-wrapper {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    .system-map-container {
      height: 100%;
      .system-map-selected {
        width: 300px;
        // height: 200px;
        max-height: 300px;
        overflow-y: scroll;
        background-color: $purple;
        color: white;
        padding: 25px 30px;
        position: absolute;
        right: 50px;
        top: 150px;
        border-radius: 8px;

        a.close {
          position: absolute;
          top: 8px;
          right: 15px;
          color: white;
          @include font-strong-m;
          &:hover {
            opacity: 0.5;
          }
        }
      }

      .system-map-selected-title {
        @include font-sans-s;
        text-transform: uppercase;
        color: rgba(white, 50);
      }

      .system-map-selected-link {
        @include font-strong-m;
        a {
          margin-bottom: 8px;
          &::before {
            content: '›  ';
          }
          &:hover {
            opacity: 0.5;
            color: white;
            //   &::after {
            //     content: " →";
            // }
          }
        }
      }
    }
    .geo-map-container {
      .zoom-control {
        right: 20px !important;
        bottom: 20px !important;
        inset: auto 20px 20px auto !important;
      }

      .map-card-title {
        font-family: 'Sohne Kraftig', sans-serif;
        font-size: 17px;
        letter-spacing: 0em;
        line-height: 1.05em;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        margin-bottom: 0.4em;
        margin-top: 0.3em;
      }
    }
  }
}

// --------------------
// Round Photo
// --------------------

.round-img {
  width: 160px;
  height: 160px;
  border-radius: 200px;
  background-color: $grey-lll;
  background-size: cover;
  background-position: center center;

  &.thumb {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
}

// --------------------
// Stakeholders cards
// --------------------

.stakeholders-cards-container {
  margin-bottom: -40px;
}

section.stakeholders-cards {
  // background-color: $grey-lll;

  .card-stakeholder-col {
    padding-top: 15px;
    margin-bottom: -5px;
  }

  .card-stakeholder {
    background-color: white;
    height: calc(100% - 20px);
    padding-bottom: $space * 1.5;
    cursor: pointer;
    @include transition(box-shadow 200ms);
    @include box-shadow(0, 0, 20px, rgba(black, 0));

    &:hover {
      @include box-shadow(0, 0, 20px, rgba(black, 0.15));
    }

    header {
      border-bottom: 1px solid $grey-lll;
      padding: $space;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .left {
        .card-title {
          @include font-strong-m;
          margin-bottom: 0.4em;
          margin-top: 0.3em;
        }
      }
      .right {
        margin-left: $space;
      }
    }
    div.card-body {
      padding: $space;
    }
  }
}

// --------------------
// Stakeholder page
// --------------------

.stakeholder-page {
  // .expert .initiative .stakeholder
}

// --------------------
// About page
// --------------------

.about {
  .intro-image {
    height: 450px;
    max-height: 70vh;
    background-image: url('./assets/images/about-large.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 80%;
  }

  .geographic-context {
    background-image: none !important;
  }

  img.about-map {
    max-height: 600px !important;
  }
}

// --------------------
// Footer
// --------------------

footer#footer {
  background-color: $blackish;
  img.logos {
    width: 100%;
  }
}

// --------------------
// Pieces
// --------------------

.loadingWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: $purple;
  display: flex;
  justify-content: center;
  align-items: center;
}

.large-small-header-combo {
  margin-top: $space * 1.5;
  height: 60px;
  h2 {
    @include font-strong-ll;
  }
  h5 {
    @include font-strong-m;
    // text-transform: uppercase;
    padding-top: 16px;
  }
}

// --------------------
// Forms
// --------------------

.system-map-container {
  .btn {
    margin: 0;
    text-align: center;
    border: none;
    background: rgb(249, 249, 249);
    color: black;
    padding: 0 4px;
    border-top: 1px solid #0a0a0a;
  }
  .btn-lg {
    font-size: 12px;
    line-height: 1;
    padding: 4px;
  }
  .btn-zoom {
    font-size: 18px;
    height: 26px;
    width: 26px;
  }
  .btn-up {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .btn-down {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .btn-bottom {
    margin-bottom: 1rem;
  }
  .description {
    font-size: 12px;
    margin-right: 0.25rem;
  }
  .controls {
    position: absolute;
    bottom: 85px;
    right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .relative {
    position: relative;
  }
}

#form-wrapper .container-fluid {
  max-width: 700px;
  padding-bottom: $space * 3;

  .MuiInputBase-input {
    margin: 10px 0;
  }

  .MuiInputBase-root {
    margin: 8px 0;
  }

  .MuiFormGroup-root {
    margin-top: 15px;
  }

  .MuiFormControl-root {
    width: 100%;

    label {
      @include font-strong-m;
      color: black;
    }
  }

  label + .MuiInput-formControl {
    margin-top: 1.3em;

    .MuiInputBase-input {
      @include font-strong-m;
      height: 1.6em;
      padding: 0;
    }
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid black !important;
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(0, 40px) scale(1);
    pointer-events: none;
    &.MuiInputLabel-shrink {
      transform: translate(0px, 5px) scale(0.75);
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: $purple;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: $purple;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: rgba($purple, 0.5);
  }

  .MuiOutlinedInput-root {
    // input {
    //   z-index: 2;
    //   @include font-strong-ll;
    //   height: .2em;
    // }
  }

  .MuiOutlinedInput-input {
    z-index: 2;
    @include font-strong-ll;
    height: 1.3em;
    padding: 0;
    border-bottom: 1px solid black;
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    background-color: rgba(white, 0);
    z-index: 1;
    legend {
    }
  }

  .submitButton {
    @include font-strong-ml;
    height: auto;
    border-radius: 200px;
    display: inline-block;
    padding-top: inherit;
    padding-bottom: inherit;
    padding: 7px 0.5em 9px;
    text-transform: uppercase;
    margin: 60px auto 30px;
    // width: 400px;
    background-color: black;
    color: white;
    @include transition(background-color 150ms, color 150ms);
    &:hover {
      background-color: darken($purple, 15%);
      background-color: $purple;
    }
  }
}
